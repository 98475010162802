<template>
  <v-card>
    <v-toolbar color="primary" dense>
      <span>{{ pack.name }}</span>
      <v-spacer />
      <v-icon color="red" @click="$emit('removePack', pack.id)">delete</v-icon>
    </v-toolbar>
    <v-card-text>
      <!-- Accounts table -->
      <v-simple-table class="accountsTable" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Tipo de cuenta</th>
              <th>Número</th>
              <th>Disponible</th>
              <th>Fianza</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="account in pack.accounts.filter((e) => e.type === 'FEES' || e.type === 'OUTLAYS')" :key="account.id">
              <td>{{ account.type }}</td>
              <td>{{ account.number }}</td>
              <td>
                <v-text-field v-model.number="account.balance" type="number" filled dense class="mt-2 mb-n4" />
              </td>
              <td>{{ account.bail }}</td>
            </tr>
            <tr class="blue lighten-4">
              <td colspan="2">TOTAL</td>
              <td colspan="2" class="text-center">{{ totalAccountsAvailable() }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-btn block color="grey lighten-1" class="rounded-t-0" width="100%" @click="calculateData()">Calcular</v-btn>

      <!-- Calculated data table -->
      <v-simple-table v-if="pack.calculatedData" dense class="mt-5">
        <template v-slot:default>
          <tbody>
            <tr>
              <th>Enviado:</th>
              <td>
                {{ round(pack.calculatedData.amountSent) }}
              </td>
            </tr>
            <tr>
              <th>Disponible en banco:</th>
              <td>
                {{ round(pack.calculatedData.bankAmountAvailable) }}
              </td>
            </tr>
            <tr>
              <th>Dinero envío:</th>
              <td>
                {{ round(pack.calculatedData.amountToSend) }}
              </td>
            </tr>
            <tr>
              <th>Transferencia a realizar:</th>
              <td>
                <v-text-field v-model.number="pack.calculatedData.amountToTransfer" type="number" filled dense class="mt-2 mb-n4" />
              </td>
            </tr>
            <tr>
              <th>Compensación cuentas cuotas:</th>
              <td>
                <v-text-field v-model.number="pack.calculatedData.compensationFees" type="number" filled dense class="mt-2 mb-n4" />
              </td>
            </tr>
            <tr>
              <th>Compensación cuentas desembolsos:</th>
              <td>
                <v-text-field v-model.number="pack.calculatedData.compensationOutlays" type="number" filled dense class="mt-2 mb-n4" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";
import CommonMixin from "@/mixins/CommonMixin";
import * as currency from "currency.js";

export default {
  name: "SingleOffice",
  mixins: [CommonMixin],
  props: {
    pack: {
      type: Object,
      required: true
    },
    selectedProjects: {
      type: Array,
      required: true
    }
  },
  computed: {
    _() {
      return _;
    }
  },
  methods: {
    ...mapActions({
      getTotalAmountSentByOffice: "creditTransfers/getTotalAmountSentByOffice"
    }),

    // Return total amount available from balance + bail of each account
    totalAccountsAvailable() {
      let totalBalance = 0;
      let totalBail = 0;

      for (const account of this._.values(this.pack.accounts)) {
        totalBalance += account.balance;
        totalBail += account.bail;
      }

      return totalBalance - totalBail;
    },

    // Calculate data of an pack
    async calculateData() {
      const params = {
        amountSent: await this.getAmountSent(),
        bankAmountAvailable: await this.getBankAmountAvailable(),
        amountToSend: this.getAmountToSend(),
        amountToTransfer: await this.getAmountToTransfer(),
        compensationFees: await this.getCompensationFees(),
        compensationOutlays: await this.getCompensationOutlays()
      };
      this.$set(this.pack, "calculatedData", params);
    },

    // ---------------------- Calculated data ----------------------
    async getAmountSent() {
      const amountSent = await this.getTotalAmountSentByOffice([this.pack.id]);
      return amountSent;
    },

    async getBankAmountAvailable() {
      const amountSent = await this.getTotalAmountSentByOffice([this.pack.id]);
      const bankAmountAvailable = amountSent + this.totalAccountsAvailable();

      if (bankAmountAvailable >= 0) return bankAmountAvailable;
      else return 0;
    },

    getAmountToSend() {
      let amount = 0;

      for (const project of this.selectedProjects.filter((e) => e.office_id === this.pack.id)) {
        for (const transfer of project.transfers) {
          amount = currency(amount).add(currency(transfer.amount_converted));
        }
      }

      return currency(amount);
    },

    async getAmountToTransfer() {
      const amountToSend = this.getAmountToSend();
      const bankAmountAvailable = await this.getBankAmountAvailable();

      if (amountToSend - bankAmountAvailable >= 0) return amountToSend - bankAmountAvailable;
      else return 0;
    },

    async getCompensationFees() {
      // Get total available in FEES accounts - bail
      let totalBalance = 0;
      let totalBail = 0;

      for (const account of this._.values(this.pack.accounts)) {
        if (account.type === "FEES") {
          totalBalance += account.balance;
          totalBail += account.bail;
        }
      }

      const availableInFeesAccounts = totalBalance - totalBail;

      // Return all the available if it is not enough with what is available in OUTLAYS accounts
      const availableInOutlaysAccounts = await this.getCompensationOutlays();
      const amountToSend = this.getAmountToSend();

      if (amountToSend > availableInOutlaysAccounts) {
        const amountToSendWithoutAvailableInOutlaysAccounts = amountToSend - availableInOutlaysAccounts;

        if (availableInFeesAccounts > 0) {
          return availableInFeesAccounts >= amountToSendWithoutAvailableInOutlaysAccounts
            ? amountToSendWithoutAvailableInOutlaysAccounts
            : availableInFeesAccounts;
        } else return 0;
      } else return 0;
    },

    async getCompensationOutlays() {
      // Get total available in OUTLAUS accounts - (bail and already sent)
      let totalBalance = 0;
      let totalBail = 0;
      const amountSent = await this.getTotalAmountSentByOffice([this.pack.id]);

      for (const account of this._.values(this.pack.accounts)) {
        if (account.type === "OUTLAYS") {
          totalBalance += account.balance;
          totalBail += account.bail;
        }
      }

      const availableInOutlaysAccounts = totalBalance - totalBail - amountSent;

      // Return all available in OUTLAUS accounts as long as it's not more than necessary
      const amountToSend = this.getAmountToSend();

      if (availableInOutlaysAccounts > 0) {
        return availableInOutlaysAccounts >= amountToSend ? amountToSend : availableInOutlaysAccounts;
      } else return 0;
    }
  }
};
</script>

<style scoped>
.accountsTable tbody td:first-child {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.accountsTable tbody td {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
