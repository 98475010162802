<template>
  <div>
    <v-expansion-panels v-model="panelOffices" multiple>
      <v-expansion-panel v-for="office in offices" :key="office.id" class="mb-3 secondary">
        <v-expansion-panel-header class="py-0">
          {{ office.name }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="white">
          <v-expansion-panels class="mt-2">
            <v-expansion-panel
              v-for="project in projectsTransfer.filter((e) => e.office_id === office.id)"
              :key="project.project_id"
              class="mb-3 secondary"
              :class="isProjectSelected(project) ? 'borderSelected' : ''"
            >
              <v-expansion-panel-header class="py-0">
                {{ project.project_id }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="white">
                <ProjectSummaryTable :project="project" :isEditable="true" />
                <div class="text-right">
                  <v-btn class="mt-3" @click="toggleProject(project)">
                    {{ isProjectSelected(project) ? "Quitar del envío" : "Añadir al envío" }}
                  </v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ProjectSummaryTable from "@/components/elements/credit-transfers/ProjectSummaryTable.vue";

export default {
  name: "ProjectListForTransfer",
  components: {
    ProjectSummaryTable
  },
  props: {
    projectsTransfer: {
      type: Array,
      required: true
    },
    selectedProjects: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      panelOffices: []
    };
  },
  computed: {
    ...mapState({
      offices: (state) => state.offices.offices
    })
  },
  watch: {
    projectsTransfer() {
      for (const project of this.projectsTransfer) {
        this.selectedProjects.push(project);
      }
    }
  },
  created() {
    this.$store.commit("offices/SET_OFFICES", []);
  },
  methods: {
    isProjectSelected(project) {
      return this.selectedProjects.some((e) => e.project_id === project.project_id);
    },

    toggleProject(project) {
      const index = this.selectedProjects.findIndex((e) => e.project_id === project.project_id);
      if (index >= 0) this.selectedProjects.splice(index, 1);
      else this.selectedProjects.push(project);
    }
  }
};
</script>

<style scoped>
.borderSelected {
  border: 2px solid #00faad !important;
  border-radius: 3px;
}
</style>
